import { Button, Center, HStack, Img, Stack, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FiChevronLeft } from "react-icons/fi"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ContentProps, Get, GetDraft } from "../../../services/ContentService"
import { MainTemplate } from "../../../templates/MainTemplate"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"
import { ContentDetailsCard } from "../components/ContentDetailsCard"

interface ViewContentPageProps{
    isPreview?: boolean;
}

export const ViewContentPage = ({ isPreview:is_preview }: ViewContentPageProps) => {
    const [content, setContent] = useState<ContentProps>()
    const { id } = useParams()
    const navigate = useNavigate()
    const { isMobile } = useScreenSize()

    useEffect(() => {
        (async () => {
            if (id) {
                if(is_preview){
                    const result = await GetDraft(parseInt(id))
                    setContent(() => result)
                }else{
                    const result = await Get(id)
                    setContent(() => result)
                }
            }
        })()
    }, [id])

    return (
        <MainTemplate>
            <VStack w={'full'} justify={'center'}>
                {content &&
                    <VStack w={'full'} maxW={'1340px'} spacing={'32px'} align={'start'}>
                        <Button onClick={() => navigate(-1)} leftIcon={<FiChevronLeft />}>Back</Button>
                        <Stack direction={isMobile ? 'column-reverse' : 'row'} spacing={'20px'} w={'full'}>
                            <ContentDetailsCard w={'full'} flexGrow={1} data={content} />
                            
                            <Img w={'500px'} 
                                h={'500px'} 
                                cursor={'pointer'}  
                                src={content.thumbnail_url} 
                                objectFit={'cover'}
                                onClick={()=> navigate(`/contents/read/${content.code}`)} 
                            />
                        </Stack>
                    </VStack>
                }
            </VStack>
        </MainTemplate>
    )
}